// ** React Imports
import { useEffect, useState, ReactNode } from 'react'

// ** Next router
import Router from 'next/router'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Modules Imports
import { useIntl } from 'react-intl'

// ** Source code Imports
import PathToRegExp from 'src/utils/paths'
import allowedPaths from 'src/utils/paths/allowed-paths'
import ErrorPage from 'src/views/pages/error-page'

const Error404 = () => {
  const { formatMessage } = useIntl()

  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    const pathToRegExp = new PathToRegExp(window.location.pathname)
    const isRedirectToPage = pathToRegExp.matchAll(allowedPaths)

    if (isRedirectToPage) {
      Router.replace(window.location.pathname)
    } else {
      setIsNotFound(true)
    }
  }, [])

  if (isNotFound)
    return (
      <ErrorPage
        code={404}
        title={formatMessage({ id: 'page-not-found' })}
        description={formatMessage({ id: 'page-not-found-description' })}
      />
    )

  return null
}

Error404.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Error404
