class PathToRegExp {
  pathName: string

  constructor(pathName: string) {
    this.pathName = pathName
  }

  match(pathAlias: string) {
    const pathNameSliced: string[] = this.pathName.split('/')
    const pathAliasSliced: string[] = pathAlias.split('/')

    let isCorrectPath = true

    pathNameSliced.forEach((sliceOfPath, index) => {
      const sliceOfAlias = pathAliasSliced[index]

      if (!sliceOfAlias && !sliceOfPath) return

      const isParam = sliceOfAlias?.[0] === ':'

      if (!sliceOfPath) {
        isCorrectPath = false
      } else if (!isParam && sliceOfPath !== sliceOfAlias) {
        isCorrectPath = false
      }
    })

    return isCorrectPath
  }

  matchAll(pathAliasList: string[]) {
    return !!pathAliasList.find(pathAlias => this.match(pathAlias))
  }
}

export default PathToRegExp
